import { render, staticRenderFns } from "./servicesSection.vue?vue&type=template&id=3eaab342&lang=html&"
import script from "./servicesSection.vue?vue&type=script&lang=js&"
export * from "./servicesSection.vue?vue&type=script&lang=js&"
import style0 from "./servicesSection.vue?vue&type=style&index=0&id=3eaab342&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeadingBlock: require('/var/www/app/components/base/headings/headingBlock.vue').default,ImplantationsSvg: require('/var/www/app/components/base/implantationsSvg.vue').default,SkewedCard: require('/var/www/app/components/base/skewedCard.vue').default})
