
export default {
  props: {
    page: {
      type: Object
    }
  },
  async fetch () {
    try {
      this.types = await this.$strapi.find('type-de-camping-cars')
    } catch (e) {
      this.types = []
    }
  },
  data: () => ({
    types: []
  })
}
