
export default {
  props: {
    slug: {
      type: String,
      default: 'profile'
    },
    color: {
      type: String,
      default: '#ff9966'
    }
  },
  data: () => ({

  })
}
